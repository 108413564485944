<template>
  <div class="form-container" data-lenis-prevent>
    <div class="form-close" @click="closeForm">
      <CloseIcon />
      <span>Close</span>
    </div>
    <div class="form-content">
      <h2 class="form-title">{{ contact.data.title }}</h2>

      <PrismicRichText class="form-copy" :field="contact?.data?.content" />
    </div>
  </div>

  <form class="form-container" data-lenis-prevent @submit.prevent="onSubmit">
    <!-- Email -->
    <div class="form-group">
      <label for="email">E-mail</label>
      <input
        id="email"
        v-model="formData.email"
        type="email"
        placeholder="Enter your Email address"
        required
      />
    </div>

    <!-- Subject -->
    <div class="form-group">
      <label for="subject">Subject</label>
      <select id="subject" v-model="formData.subject" required>
        <option value="" disabled selected>Select subject</option>
        <option value="general">General Inquiry</option>
        <option value="support">Support</option>
        <option value="sales">Sales</option>
        <!-- Ajoutez d'autres options selon vos besoins -->
      </select>
    </div>

    <!-- Name -->
    <div class="form-group">
      <label for="name">Name</label>
      <input
        id="name"
        v-model="formData.name"
        type="text"
        placeholder="Enter your Name"
        required
      />
    </div>

    <!-- Message -->
    <div class="form-group">
      <label for="message">Please enter the details of your request:</label>
      <textarea
        id="message"
        v-model="formData.message"
        rows="6"
        placeholder="Your message"
        required
      />
    </div>

    <!-- Submit -->
    <button type="submit" class="submit-button">Submit</button>
  </form>
</template>

<script setup>
import CloseIcon from "@/assets/images/icons/close.svg";
import { useFormsStore } from "@/stores/forms";

const { client } = usePrismic();
const { data: contact } = await useAsyncData("contactForm", () =>
  client.getSingle("hire_team_form")
);

const formStore = useFormsStore();

const closeForm = () => {
  formStore.closeForm();
};

const formData = reactive({
  email: "",
  subject: "",
  name: "",
  message: "",
});

const onSubmit = async () => {
  try {
    // Prepare query parameters
    const params = new URLSearchParams({
      formName: "hireTeam",
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    });

    // Send to Zapier webhook
    const response = await fetch(
      `https://hooks.zapier.com/hooks/catch/21290808/2gdjy1j/?${params.toString()}`,
      { method: "POST" }
    );

    if (response.ok) {
      alert("Form submitted successfully!");
      formStore.closeForm();
    } else {
      throw new Error("Submission failed");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    alert("Failed to submit form. Please try again.");
  }
};
</script>

<style lang="scss" src="@/assets/css/components/form.scss" scoped />
