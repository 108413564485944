<template>
  <div v-if="shouldShowPreloader" ref="el" class="preloader">
    <div ref="content" class="content">
      <h2 class="first">
        <span ref="first">The home of the</span>
      </h2>
      <h2 class="second">
        <span ref="second">Tailored Space</span>
      </h2>
    </div>

    <div ref="logoContainer" class="title-container">
      <Logo ref="logo" class="homepage-hero--title" />
    </div>
  </div>
</template>

<script setup>
import Logo from "@/assets/images/homepage/logo-homepage.svg?skipsvgo";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

const el = shallowRef(null);
const logo = shallowRef(null);
const logoContainer = shallowRef(null);

const content = shallowRef(null);
const first = shallowRef(null);
const second = shallowRef(null);

const { $lenis } = useNuxtApp();

let ctx;

const route = useRoute();

onMounted(() => {
  const hasSeenPreloader = sessionStorage.getItem("hasSeenPreloader");

  if (hasSeenPreloader) {
    shouldShowPreloader.value = false;
    showCookieBanner();
    $lenis.start();
    return;
  }

  sessionStorage.setItem("hasSeenPreloader", "true");

  gsap.set(".current-page", {
    y: window.innerHeight / 4,
  });

  if (route.name === "index") {
    gsap.set(".hht", {
      display: "none",
    });
  } else {
    gsap.set(el.value, {
      overflow: "hidden",
    });
  }

  initAnimations();
});

const initAnimations = () => {
  splitTexts();
  initTextAnimations();
  initSVGAnimation();
};

const shouldShowPreloader = ref(true);

let allFirstChars, allSecondChars;

const splitTexts = () => {
  new SplitText(second.value, {
    type: "chars",
    charsClass: "--char",
  });

  new SplitText(first.value, {
    type: "chars",
    charsClass: "--char",
  });

  allFirstChars = first.value.querySelectorAll(".--char");
  allSecondChars = second.value.querySelectorAll(".--char");
};

const initTextAnimations = () => {
  ctx = gsap.context(() => {
    gsap.set(second.value, {
      xPercent: 40,
    });

    gsap.set(allSecondChars, {
      yPercent: 100,
    });

    gsap.set(first.value, {
      xPercent: 100,
    });

    gsap.set([first.value, second.value], {
      autoAlpha: 1,
    });

    gsap.to(first.value, {
      xPercent: 0,
      duration: 1.5,
      ease: "power2.inOut",
      delay: 0.5,
    });

    gsap.to(second.value, {
      xPercent: 0,
      duration: 1.5,
      ease: "power2.inOut",
      delay: 0.7,
    });

    gsap.to(allSecondChars, {
      yPercent: 0,
      duration: 1.2,
      ease: "power2.inOut",
      stagger: 0.03,
      delay: 0.85,
      onComplete: () => {
        hidePreloader();
      },
    });
  });
};

const hidePreloader = () => {
  allFirstChars.forEach((char, index) => {
    gsap.set(char, {
      opacity: 0,
      delay: index * 0.035,
    });
  });

  allSecondChars.forEach((char, index) => {
    gsap.set(char, {
      opacity: 0,
      delay: 0.4 + index * 0.035,
    });
  });

  gsap.to(".current-page", {
    y: 0,
    duration: 1.3,
    ease: "power3.inOut",
    onComplete: () => {
      ScrollTrigger.refresh(true);
    },
  });

  gsap.to(el.value, {
    yPercent: -100,
    duration: 1.4,
    ease: "power3.inOut",
  });

  gsap.delayedCall(1.4, () => {
    $lenis.start();
    showCookieBanner();
    if (route.name === "index") {
      gsap.set(".hht", {
        display: "block",
      });
    }
    shouldShowPreloader.value = false;
  });

  gsap.to(content.value, {
    y: window.innerHeight / 2,
    duration: 1.4,
    ease: "power3.inOut",
  });

  if (route.name !== "index") {
    gsap.to(logo.value.$el, {
      yPercent: 120,
      duration: 1.8,
      delay: 2.2,
      ease: "power2.inOut",
    });
  }

  gsap.fromTo(
    logoContainer.value,
    {
      yPercent: 0,
    },
    {
      yPercent: 100,
      duration: 1.4,
      ease: "power3.inOut",
      onComplete: () => {
        gsap.set(logo.value.$el, {
          autoAlpha: 0,
        });

        document
          .querySelector(".current-page")
          .style.removeProperty("transform");
      },
    }
  );
};

const initSVGAnimation = () => {
  const svgEl = logo.value.$el;
  const letters = svgEl.querySelectorAll(".svg-letter");

  ctx.add(() => {
    const tl = gsap.timeline();

    tl.set(svgEl, {
      autoAlpha: 1,
    });

    tl.fromTo(
      letters,
      {
        y: () => window.innerHeight * 1.2,
      },
      {
        y: 0,
        stagger: {
          from: "start",
          amount: 0.2,
        },
        duration: 1.8,
        ease: "expo.out",
      },
      1
    );
  });
};

const showCookieBanner = () => {
  const cookieBanner = document.querySelector(".cookie-banner");
  if (cookieBanner) {
    gsap.to(cookieBanner, {
      autoAlpha: 1,
      duration: 1,
      delay: 1,
      ease: "expo.out",
    });
  }
};

onBeforeUnmount(() => {
  ctx?.kill(false);
});
</script>

<style lang="scss" scoped>
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: $black;
  z-index: 999;
  padding: 0 var(--padding-mobile);
  pointer-events: none;
  will-change: transform;
  @include desktop {
    padding: 0 $padding-desktop;
  }
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-end;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 0.4rem;
}

.first {
  color: #fff;
  text-align: center;
  font-family: $beaufort-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 114.286% */
  letter-spacing: -0.42px;
}

.second {
  color: #fff;
  font-family: $beaufort-regular;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.51px;
  text-transform: uppercase;
}

.first,
.second {
  display: inline-block;
  overflow: hidden;
  will-change: transform;
  span {
    display: inline-block;
    visibility: hidden;
    will-change: transform;
  }
}

.title-container {
  position: relative;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 1rem;
}

.homepage-hero--title {
  z-index: 1;
  width: 100%;
  visibility: hidden;
}
</style>
