// stores/cookies.ts
import { defineStore } from "pinia";

export const useCookieStore = defineStore("cookies", {
  state: () => ({
    hasChosenPreference: false,
    accepted: false,
  }),

  actions: {
    checkPreferences() {
      const cookieChoice = useCookie("cookie-preference");
      if (cookieChoice.value) {
        this.hasChosenPreference = true;
        this.accepted = cookieChoice.value === "accepted";
      }
    },

    accept() {
      const cookie = useCookie("cookie-preference", {
        maxAge: 60 * 60 * 24 * 365, // 1 year
      });
      cookie.value = "accepted";
      this.accepted = true;
      this.hasChosenPreference = true;
    },

    dismiss() {
      const cookie = useCookie("cookie-preference");
      cookie.value = "dismissed";
      this.accepted = false;
      this.hasChosenPreference = true;
    },
  },
});
