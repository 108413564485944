<template>
  <div ref="el" class="form-overlay" @click="closeOverlay">
    <div ref="form" class="form">
      <ContactForm v-if="formStore.isContactFormOpened" />
      <RegisterFloorForm v-if="formStore.isRegisterFloorFormOpened" />
      <HireTeamForm v-if="formStore.isHireTeamFormOpened" />
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import ContactForm from "@/components/forms/ContactForm.vue";
import RegisterFloorForm from "@/components/forms/RegisterFloorForm.vue";
import HireTeamForm from "@/components/forms/HireTeamForm.vue";
import { useFormsStore } from "@/stores/forms";

const formStore = useFormsStore();

const el = ref(null);
const form = ref(null);

onMounted(() => {
  gsap.to([el.value, form.value], {
    autoAlpha: 1,
    duration: 0.5,
    ease: "power3.inOut",
  });
  gsap.fromTo(
    form.value,
    {
      x: el.value.offsetWidth / 2,
    },
    {
      x: 0,
      duration: 1,
      ease: "power3.inOut",
    }
  );
});

const closeOverlay = (e) => {
  if (e.target.classList.contains("form-overlay")) {
    gsap.fromTo(
      form.value,
      {
        x: 0,
      },
      {
        x: el.value.offsetWidth / 2,
        duration: 1,
        ease: "power3.inOut",
        onComplete: formStore.closeForm,
      }
    );

    gsap.to([el.value, form.value], {
      autoAlpha: 0,
      duration: 0.5,
      delay: 0.3,
      ease: "power3.inOut",
    });
  }
};
</script>

<style lang="scss" scoped>
.form-overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #0000005d, $alpha: 0.5);
  z-index: 11;
  cursor: pointer;
  overflow-y: scroll;
  visibility: hidden;
  overflow-x: hidden;
}

.form {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $white;
  min-height: 100vh;
  cursor: initial;
  width: 100%;
  visibility: hidden;
  @include desktop {
    width: 50%;
  }
}
</style>
