<template>
  <div ref="el" class="cookie-banner">
    <p class="paragraph">
      To offer you a better experience, this site uses cookies and similar
      technologies. By selecting "Accept all" you agree to their use. For more
      information read our
      <NuxtLink to="/legals/terms-of-use">Cookie Policy</NuxtLink> and
      <NuxtLink to="/legals/terms-of-use">Privacy Policy</NuxtLink>.
    </p>

    <div class="buttons-container">
      <button id="accept" @click="acceptCookies">Accept All</button>
      <button id="dismiss" @click="dismissCookies">Dismiss</button>
    </div>
  </div>
</template>

<script setup>
import { useCookieStore } from "@/stores/cookies";
const cookieStore = useCookieStore();

const { gtag, initialize } = useGtag();

const route = useRoute();
const el = shallowRef(null);

// State pour gérer l'affichage de la bannière
const showBanner = ref(true);

function acceptCookies() {
  cookieStore.accept();
  initialize();
  gtag("consent", "update", {
    analytics_storage: "granted",
    ad_storage: "granted",
  });
  useTrackEvent("page_view");
}

function dismissCookies() {
  cookieStore.dismiss();
}

onMounted(() => {
  if (route.name === "index") {
    el.value.classList.add("--hp");
  }
  const accepted = localStorage.getItem("cookies-accepted");
  if (accepted === "true") {
    acceptCookies();
  } else if (accepted === "false") {
    showBanner.value = false;
  }
});
</script>

<style lang="scss" scoped>
.cookie-banner {
  width: calc(100% - var(--padding-mobile) * 2);
  background-color: $black;
  height: auto;
  position: fixed;
  bottom: var(--padding-mobile);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  padding: 2rem;
  visibility: hidden;
  @include desktop {
    width: 31.5rem;
    left: $padding-desktop;
    bottom: $padding-desktop;
    transform: unset;
  }

  &.--hp {
    bottom: calc(var(--padding-mobile) + 9rem);
    @include tablet {
      bottom: calc(var(--padding-mobile) + 12rem);
    }
    @include desktop {
      bottom: $padding-desktop;
    }
  }
}

.paragraph {
  color: #fff;
  font-family: $suisse-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.761px;
  margin-bottom: 1rem;
  a {
    text-decoration: underline;
  }
  @include desktop {
    margin-bottom: 2rem;
  }
}

.buttons-container {
  display: flex;
  gap: 1rem;
  button {
    width: 50%;
    background-color: red;
    padding: 1.1rem 0;
    border-radius: 3.5rem;
    text-align: center;
    font-family: $suisse-regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.12px;
  }

  #accept {
    background-color: $white;
    color: $black;
    border: solid 1px $white;
  }

  #dismiss {
    background-color: $black;
    border: solid 1px $white;
    color: $white;
  }
}
</style>
