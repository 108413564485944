<template>
  <div class="form-container">
    <div class="form-close" @click="closeForm">
      <CloseIcon />
      <span>Close</span>
    </div>
    <div class="form-content">
      <h2 class="form-title">{{ form.data.title }}</h2>

      <PrismicRichText class="form-copy" :field="form?.data?.content" />
    </div>
    <form class="form" data-lenis-prevent @submit.prevent="onSubmit">
      <!-- Nom -->
      <div class="form-group">
        <label for="name">Name</label>
        <input
          id="name"
          v-model="formData.name"
          type="text"
          placeholder="Enter your Name"
          required
        />
      </div>

      <!-- Adresse -->
      <div class="form-group">
        <label for="address">Address</label>
        <input
          id="address"
          v-model="formData.address"
          type="text"
          placeholder="Enter your Address"
          required
        />
      </div>

      <!-- Ville -->
      <div class="form-group">
        <label for="city">City</label>
        <input
          id="city"
          v-model="formData.city"
          type="text"
          placeholder="Enter your City"
          required
        />
      </div>

      <!-- ZIP -->
      <div class="form-group">
        <label for="zip">ZIP</label>
        <input
          id="zip"
          v-model="formData.zip"
          type="text"
          placeholder="Enter your ZIP Code"
          required
        />
      </div>

      <!-- Email -->
      <div class="form-group">
        <label for="email">E-mail</label>
        <input
          id="email"
          v-model="formData.email"
          type="email"
          placeholder="Enter your Email address"
          required
        />
      </div>

      <!-- Date d'achat -->
      <div class="form-group">
        <label for="datePurchased">Date purchased</label>
        <input
          id="datePurchased"
          v-model="formData.datePurchased"
          type="date"
          placeholder="Enter Date purchased"
          required
        />
      </div>

      <!-- Date d'installation -->
      <div class="form-group">
        <label for="dateInstalled">Date installed</label>
        <input
          id="dateInstalled"
          v-model="formData.dateInstalled"
          type="date"
          placeholder="Enter Date installed"
          required
        />
      </div>

      <!-- Style de plancher -->
      <div class="form-group">
        <label for="floorStyle">Floor style</label>
        <select id="floorStyle" v-model="formData.floorStyle" required>
          <option value="" disabled selected hidden>Select Floor style</option>
          <option value="hardwood">Hardwood</option>
          <option value="vinyl">Vinyl</option>
          <option value="laminate">Laminate</option>
          <!-- Ajoutez d'autres options selon vos besoins -->
        </select>
      </div>

      <!-- Acheté chez -->
      <!-- <div class="form-group">
        <label for="purchasedFrom">Purchased from</label>
        <select id="purchasedFrom" v-model="formData.purchasedFrom" required>
          <option value="" disabled selected hidden>Select store</option>
          <option value="store1">Store 1</option>
          <option value="store2">Store 2</option>
          <option value="store3">Store 3</option>
        </select>
      </div> -->

      <!-- Pièces installées -->
      <div class="form-group">
        <label for="roomsInstalled">Rooms installed</label>
        <input
          id="roomsInstalled"
          v-model="formData.roomsInstalled"
          type="text"
          placeholder="Enter Names of Rooms installed"
          required
        />
      </div>

      <!-- Quantité achetée -->
      <div class="form-group">
        <label for="quantityPurchased">Quantity purchased</label>
        <input
          id="quantityPurchased"
          v-model="formData.quantityPurchased"
          type="number"
          placeholder="Enter Quantity purchased"
          required
        />
      </div>

      <!-- Comment avez-vous entendu parler de nous -->
      <div class="form-group">
        <label for="source">How did you hear about DUCHATEAU?</label>
        <select id="source" v-model="formData.source" required>
          <option value="" disabled selected>Select</option>
          <option value="friend">Friend</option>
          <option value="internet">Internet</option>
          <option value="social">Social Media</option>
          <!-- Ajoutez d'autres options selon vos besoins -->
        </select>
      </div>

      <!-- Submit -->
      <button type="submit" class="submit-button">Submit</button>
    </form>
  </div>
</template>

<script setup>
import CloseIcon from "@/assets/images/icons/close.svg";
import { useFormsStore } from "@/stores/forms";

const { client } = usePrismic();
const { data: form } = await useAsyncData("hireForm", () =>
  client.getSingle("register_floor_form")
);

const formStore = useFormsStore();

const closeForm = () => {
  formStore.closeForm();
};

const formData = reactive({
  name: "",
  address: "",
  city: "",
  zip: "",
  email: "",
  datePurchased: "",
  dateInstalled: "",
  floorStyle: "",
  roomsInstalled: "",
  quantityPurchased: "",
  source: "",
});

const onSubmit = async () => {
  try {
    // Prepare query parameters
    const params = new URLSearchParams({
      formName: "registerFloor",
      name: formData.name,
      address: formData.address,
      city: formData.city,
      zip: formData.zip,
      email: formData.email,
      datePurchased: formData.datePurchased,
      dateInstalled: formData.dateInstalled,
      floorStyle: formData.floorStyle,
      roomsInstalled: formData.roomsInstalled,
      quantityPurchased: formData.quantityPurchased.toString(),
      source: formData.source,
    });

    // Send to Zapier webhook
    const response = await fetch(
      `https://hooks.zapier.com/hooks/catch/21290808/2gdvdlw/?${params.toString()}`,
      { method: "POST" }
    );

    if (response.ok) {
      alert("Form submitted successfully!");
      formStore.closeForm();
    } else {
      throw new Error("Submission failed");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    alert("Failed to submit form. Please try again.");
  }
};
</script>

<style lang="scss" src="@/assets/css/components/form.scss" scoped />
