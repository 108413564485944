<template>
  <div class="custom-cursor-wrapper">
    <div
      ref="cursor"
      class="cursor-container"
      :class="{ 'is-visible': cursorStore.isVisible }"
    >
      {{ cursorStore.wording }}
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCursorStore } from "@/stores/cursorStore";
import { expDecay } from "@/utils/lerp";
import { gsap } from "gsap";

const cursorStore = useCursorStore();
const { wording, isVisible } = storeToRefs(cursorStore);

const cursor = ref(null);

const mouse = {
  x: 0,
  y: 0,
};

const lerpedMouse = {
  x: 0,
  y: 0,
};

const cursorWidth = ref(0);

watch(
  () => cursorStore.wording,
  (newWording, oldWording) => {
    onResize();
  }
);

watch(
  () => cursorStore.isVisible,
  () => {
    onResize();
  }
);

onMounted(() => {
  gsap.ticker.add(update);
  window.addEventListener("mousemove", onMouseMove);
});

const onMouseMove = (e) => {
  mouse.x = e.clientX + 15;
  mouse.y = e.clientY + 15;
};

const update = (time, dt) => {
  if (!cursor.value) return;
  const x = expDecay(lerpedMouse.x, mouse.x, 10, dt / 1000);
  const y = expDecay(lerpedMouse.y, mouse.y, 10, dt / 1000);

  lerpedMouse.x = x;
  lerpedMouse.y = y;

  cursor.value.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
};

const onResize = () => {
  nextTick(() => {
    cursorWidth.value = cursor.value.offsetWidth;
  });
};

onBeforeUnmount(() => {
  gsap.ticker.remove(update);
  window.removeEventListener("mousemove", onMouseMove);
});
</script>

<style lang="scss" scoped>
.custom-cursor-wrapper {
  display: none;

  @include desktop {
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    pointer-events: none;
    z-index: 9;
  }
}

.cursor-container {
  background-color: $beige;
  padding: 1.1rem 0.7rem;
  border-radius: 0.5rem;
  width: fit-content;
  opacity: 0;
  transition: opacity 0.3s ease;

  color: #000;
  text-align: center;
  font-family: $social-bold;
  font-size: 1.1rem;
  font-style: normal;
  line-height: 1.3rem;
  letter-spacing: -0.0165rem;
  text-transform: uppercase;

  &.is-visible {
    opacity: 1;
  }
}
</style>
