<template>
  <div class="page__view">
    <Preloader />

    <CustomCursor />

    <FormOverlay v-if="isFormOpened" />

    <MenuMobile v-show="isMobile && !isSamples" />
    <MenuMobileOverlay v-show="isMobile" />

    <MenuDesktop v-show="!isMobile && !isSamples" />

    <CBanner v-if="!cookieStore.hasChosenPreference" />

    <slot />
    <!-- <DesignGrid /> -->
  </div>
</template>

<script setup>
import CBanner from "~/components/CBanner.vue";
import MenuDesktop from "@/components/menu/MenuDesktop.vue";
import MenuMobile from "@/components/menu/MenuMobile.vue";
import Preloader from "@/components/Preloader.vue";
import FormOverlay from "~/components/forms/FormOverlay.vue";

import { useCookieStore } from "@/stores/cookies";
import { useFormsStore } from "@/stores/forms";
import { useMenuStore } from "@/stores/menuStore.js";
import { useTransitionStore } from "@/stores/transition";

const transitionStore = useTransitionStore();

const { isSamples } = storeToRefs(transitionStore);

const menuStore = useMenuStore();
const formStore = useFormsStore();
const cookieStore = useCookieStore();

const { shouldStayLight } = storeToRefs(menuStore);

const { isMobile } = useMobile();

const { $lenis } = useNuxtApp();

const direction = ref(1);

const isFormOpened = computed(() => {
  return formStore.isFormOpened;
});

watch(isFormOpened, (newVal) => {
  if (newVal) {
    $lenis.stop();
  } else {
    $lenis.start();
  }
});

onMounted(() => {
  cookieStore.checkPreferences();

  initListeners();
  $lenis.scrollTo(0, {
    immediate: true,
  });
});

const { client } = usePrismic();
const { data: meta } = await useAsyncData("meta", () =>
  client.getSingle("meta")
);

const initListeners = () => {
  $lenis.on("scroll", () => {
    if (!menuStore.hasBackground) {
      menuStore.setHasBackground(true);
      if (!shouldStayLight.value) {
        menuStore.setLight(false);
      }
    }
    onScroll();
  });
};

const onScroll = () => {
  if ($lenis.animatedScroll < 20) {
    menuStore.setHasBackground(false);

    if (menuStore.shouldStayLight == "true") {
      menuStore.setLight(true);
    }
  }

  if ($lenis.direction === direction.value) return;

  direction.value = $lenis.direction;

  if (direction.value === 1) {
    menuStore.direction = 1;
  } else {
    menuStore.direction = -1;
  }
};

onBeforeUnmount(() => {});

// useHead({
//   title: meta?.value?.data?.title,
//   htmlAttrs: { lang: "en" },
//   meta: [
//     {
//       name: "theme-color",
//       content: "#000",
//     },
//     {
//       name: "description",
//       content: meta?.value?.data?.description,
//     },
//     {
//       hid: "og-title",
//       property: "og:title",
//       content: meta?.value?.data?.title,
//     },
//     {
//       hid: "og-type",
//       property: "og:type",
//       content: "website",
//     },

//     {
//       hid: "og-desc",
//       property: "og:description",
//       content: meta?.value?.data?.description,
//     },
//     {
//       hid: "og-image",
//       property: "og:image",
//       content: meta?.value?.data?.og_image.url,
//     },
//     {
//       hid: "twitter-title",
//       property: "twitter:title",
//       content: meta?.value?.data?.title,
//     },
//     {
//       hid: "twitter-desc",
//       property: "twitter:description",
//       content: meta?.value?.data?.description,
//     },
//     {
//       hid: "twitter-image",
//       property: "twitter:image",
//       content: meta?.value?.data?.og_image.url,
//     },
//     {
//       hid: "twitter-card",
//       property: "twitter:card",
//       content: "summary_large_image",
//     },
//   ],
//   link: [
//     {
//       rel: "apple-touch-icon",
//       sizes: "180x180",
//       href: "/apple-touch-icon.png",
//     },
//     {
//       rel: "icon",
//       type: "image/png",
//       sizes: "96x96",
//       href: "/favicon-96x96.png",
//     },
//     {
//       rel: "icon",
//       type: "image/png",
//       sizes: "192x192",
//       href: "/favicon-192x192.png",
//     },
//     {
//       rel: "icon",
//       type: "image/png",
//       sizes: "512x512",
//       href: "/favicon-512x512.png",
//     },
//     {
//       rel: "stylesheet",
//       href: "https://use.typekit.net/clv7tvj.css",
//     },
//   ],
//   script: [
//     {
//       textContent: `
//         window.markerConfig = {
//           project: '671ae0d77a3b33b4b915c1f0',
//           source: 'snippet'
//         };

//         !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","clearReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
//       `,
//       tagPosition: "bodyClose",
//     },
//   ],
// });
</script>
