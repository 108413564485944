<template>
  <div class="form-container" data-lenis-prevent>
    <div class="form-close" @click="closeForm">
      <CloseIcon />
      <span>Close</span>
    </div>
    <div class="form-content">
      <h2 class="form-title">{{ contact.data.title }}</h2>

      <PrismicRichText class="form-copy" :field="contact?.data?.content" />
    </div>
    <form class="form" data-lenis-prevent @submit.prevent="onSubmit">
      <!-- Nom -->
      <div class="form-group">
        <label for="name">Name</label>
        <input
          id="name"
          v-model="formData.name"
          type="text"
          placeholder="Enter your Name"
          required
        />
      </div>

      <!-- Occupation -->
      <div class="form-group">
        <label for="occupation">Occupation</label>
        <select id="occupation" v-model="formData.occupation" required>
          <option value="" disabled selected>Select Occupation</option>
          <option value="business">Business</option>
          <option value="developer">Developer</option>
          <option value="designer">Designer</option>
        </select>
      </div>

      <!-- Adresse -->
      <div class="form-group">
        <label for="address">Address</label>
        <input
          id="address"
          v-model="formData.address"
          type="text"
          placeholder="Enter your Address"
          required
        />
      </div>

      <!-- Ville -->
      <div class="form-group">
        <label for="city">City</label>
        <input
          id="city"
          v-model="formData.city"
          type="text"
          placeholder="Enter your City"
          required
        />
      </div>

      <!-- ZIP -->
      <div class="form-group">
        <label for="zip">ZIP</label>
        <input
          id="zip"
          v-model="formData.zip"
          type="text"
          placeholder="Enter your ZIP Code"
          required
        />
      </div>

      <!-- Email -->
      <div class="form-group">
        <label for="email">E-mail</label>
        <input
          id="email"
          v-model="formData.email"
          type="email"
          placeholder="Enter your Email address"
          required
        />
      </div>

      <!-- Intérêts -->
      <div class="form-group">
        <label>What are you interested in?</label>
        <div class="checkbox-group">
          <label class="checkbox-label">
            <input v-model="formData.interests.floor" type="checkbox" />
            <span>Floor</span>
          </label>
          <label class="checkbox-label">
            <input v-model="formData.interests.wallCoverings" type="checkbox" />
            <span>Wall Coverings</span>
          </label>
          <label class="checkbox-label">
            <input v-model="formData.interests.kitchens" type="checkbox" />
            <span>Kitchens</span>
          </label>
          <label class="checkbox-label">
            <input v-model="formData.interests.bathrooms" type="checkbox" />
            <span>Bathrooms</span>
          </label>
          <label class="checkbox-label">
            <input v-model="formData.interests.closets" type="checkbox" />
            <span>Closets</span>
          </label>
          <label class="checkbox-label">
            <input
              v-model="formData.interests.outdoorKitchens"
              type="checkbox"
            />
            <span>Outdoor Kitchens</span>
          </label>
          <label class="checkbox-label">
            <input v-model="formData.interests.appliances" type="checkbox" />
            <span>Appliances</span>
          </label>
        </div>
      </div>

      <!-- Source -->
      <div class="form-group">
        <label for="source">How did you hear about DUCHATEAU?</label>
        <select id="source" v-model="formData.source" required>
          <option value="" disabled selected>Select</option>
          <option value="friend">Friend</option>
          <option value="internet">Internet</option>
          <option value="social">Social Media</option>
        </select>
      </div>

      <!-- Submit -->
      <button type="submit" class="submit-button">Submit</button>
    </form>
  </div>
</template>

<script setup>
import CloseIcon from "@/assets/images/icons/close.svg";
import { useFormsStore } from "@/stores/forms";

const { client } = usePrismic();
const { data: contact } = await useAsyncData("contactForm", () =>
  client.getSingle("contactform")
);

const formStore = useFormsStore();

const closeForm = () => {
  formStore.closeForm();
};

const formData = reactive({
  name: "",
  occupation: "",
  address: "",
  city: "",
  zip: "",
  email: "",
  interests: {
    floor: false,
    wallCoverings: false,
    kitchens: false,
    bathrooms: false,
    closets: false,
    outdoorKitchens: false,
    appliances: false,
  },
  source: "",
});

const onSubmit = async () => {
  try {
    // Convert interests object to comma-separated string
    const selectedInterests = Object.entries(formData.interests)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .join(",");

    // Prepare query parameters
    const params = new URLSearchParams({
      formName: "contact",
      name: formData.name,
      occupation: formData.occupation,
      address: formData.address,
      city: formData.city,
      zip: formData.zip,
      email: formData.email,
      interests: selectedInterests,
      source: formData.source,
    });

    // Send to Zapier webhook
    const response = await fetch(
      `https://hooks.zapier.com/hooks/catch/21290808/2gdxgm8/?${params.toString()}`,
      { method: "POST" }
    );

    if (response.ok) {
      alert("Form submitted successfully!");
      formStore.closeForm();
    } else {
      throw new Error("Submission failed");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    alert("Failed to submit form. Please try again.");
  }
};
</script>

<style lang="scss" src="@/assets/css/components/form.scss" scoped />
